<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>¡Solventa tus imprevistos!</h1>
          <h1>Organiza tus gastos</h1>
          <h4>Como empresa con productos crediticios de concesión</h4>
          <h4>rápida basados en el modelo de big data y pruebas para</h4>
          <h4>el control de riesgos, nuestro objetivo es ayudarlo a superar</h4>
          <h4>fácilmente sus  dificultades y necesidades monetarias urgentes.</h4>
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
        <div class="item_info">
          <!-- <img class="info_img1" src="@/assets/images/loanImg/icon_info.png" alt=""> -->
          <div class="info_tips">
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">El proceso de aprobación es súper rápido y se hará automáticamente a <br> través de nuestro sistema</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick2.png" alt="">Salvaguardamos firmemente sus intereses y protegemos la seguridad  <br> de sus datos</p>
          </div>
          <!-- <img class="info_img2" src="@/assets/images/loanImg/icon_info2.png" alt=""> -->
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <h3>Ventajas de usar nuestra App</h3>
      <div class="inner2_item flex jc-sb">
        <div v-for="(item,index) in inner_5items" :key="index" class="content">
          <img class="bg" :src="item.img" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector2" class="inner inner3">
      <div class="title">
        <h3>Proceso de préstamo</h3>
        <!-- <p>Here are some of the key features of EasyKash that make it a great option for those looking for a loan</p> -->
      </div>
      <ul class="list">
        <li class="left_item">
          <p class="text1">Descarga la App</p>
          <p class="text2">Vaya a GooglePlay para descargar<br>MonedaCome</p>
        </li>
        <li class="right_item">
          <p class="text1">Registrarse y verificar</p>
          <p class="text2">Debe registrarse y completar la información<br>requerida para terminar la verificación.</p>
        </li>
        <li class="left_item last">
          <p class="text1">Solicitar préstamos</p>
          <p class="text2">Puede elegir el monto de préstamos de acuerdo con sus<br>necesidades y agregar una cuenta para recibir el dinero.<br>Puede solicitar préstamos y ver los resultados de las verificaciones.</p>
        </li>
        <li class="right_item last">
          <p class="text1">Obtención de préstamos</p>
          <p class="text2">Completamos el proceso de verificación en 3 minutos.<br>
            Si se aprueba, el dinero se acreditará de inmediato..</p>
        </li>
        <li class="left_item last">
          <p class="text1">Pagar el préstamo a tiempo</p>
          <p class="text2">Para aumentar el límite de crédito y evitar multas, <br>
            debe pagar a tiempo los préstamos antes de las fechas <br>
            de vencimiento.</p>
        </li>
        <li class="right_item last">
          <p class="text1">Volver a pedier préstamos</p>
          <p class="text2">Nos complace ayudarlo a alcanzar la vida de su <br>
            sueño y esperamos poder brindarle ayuda nuevamente.</p>
        </li>
      </ul>
      <div class="phone">
        <img src="@/assets/images/loanImg/phone.png" alt="">
      </div>
    </div>
    <div id="selector1" class="inner inner6">
      <!-- <h3>Nuestros usuarios quieren compartir con usted</h3> -->
      <div class="flex al-c jc-c">
        <!-- <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button> -->
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item flex fd-c jc-sb">
                <div class="flex ai-c" :style="{width:'100%'}">
                  <img class="tx" :src="item.img">
                  <div>
                    <h1>{{ item.name }}</h1>
                    <h2>{{ item.txt }}</h2>
                    <img class="stars" v-for="index in 5" :key="index" src="@/assets/images/loanImg/star.png" >
                  </div>
                </div>
                <div>
                  <p>{{ item.txt2 }}</p>
                </div>
                <div class="item_download_2 flex">
                  <div>
                    <p>Valoración</p>
                    <div class="flex ai-c">
                      <img class="stars" v-for="index in 5" :key="index" src="@/assets/images/loanImg/star.png">
                      <span>4.9</span>
                    </div>
                  </div>
                  <img class="downloan" src="@/assets/images/Google.png" alt="" @click="downloadApp()">
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <!-- <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Información perfecta', txt2: 'Solo 5 minutos', img: require('@/assets/images/itemList_01.png'), img2: require('@/assets/images/itemList_04.png') },
        { txt: 'Aprobación rápida', txt2: 'Aprobación en 1 horas', img: require('@/assets/images/itemList_02.png'), img2: require('@/assets/images/itemList_05.png') },
        { txt: 'Préstamo', txt2: 'Llegó dentro de las 24 horas', img: require('@/assets/images/itemList_03.png'), img2: require('@/assets/images/itemList_06.png') }
      ],
      leftItemList2: [
        { title: 'What can you get?', list2: [{ img: require('@/assets/images/list01.png'), txt: 'From 1,000 ₱ up to 50,000 ₱' }, { img: require('@/assets/images/list02.png'), txt: 'Repayment Term：0-180 days' }, { img: require('@/assets/images/list03.png'), txt: 'Annual interest rate：10%-35%' }] },
        { title: 'Who is eligible to get a loan?', list2: [{ img: require('@/assets/images/list04.png'), txt: 'Age 18-60 years' }, { img: require('@/assets/images/list05.png'), txt: 'Philippines citizen' }, { img: require('@/assets/images/list06.png'), txt: 'Employed or Business-owner' }] }
      ],
      inner_5items: [
        { txt: 'Solicitud 100% en línea', txt2: 'fácil acceso a préstamos sin \n documentacion', img: require('@/assets/images/itemList_04.png') },
        { txt: 'Proceso de solicitud', txt2: 'obtenga un préstamo en solo \n unos pocos pasos', img: require('@/assets/images/itemList_05.png') },
        { txt: 'Préstamo alto', txt2: 'monto del préstamo \n hasta $20,000', img: require('@/assets/images/itemList_06.png') },
        { txt: 'Conveniente y rápido', txt2: 'verificación rápida, sin \n necesidad de esperar \n mucho tiempo', img: require('@/assets/images/itemList_07.png') },
        { txt: 'Protección de seguridad', txt2: 'Su información siempre está \n protegida con nosotros', img: require('@/assets/images/itemList_08.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Estudiantes universitarios', txt2: 'Una aplicación que es intuitiva, fácil de usar, \n no se demora en otorgarte tu préstamo, \n además de que el monto de interés es \n mínimo, y el plazo es razonable. Me \n agrada lo rápido que es.', img: require('@/assets/images/Product01.png') },
        { id: '2', name: 'Alice Ura', txt: 'Esteticista', txt2: 'Excelente Aplicación, es un opción acertada \n y muy rápida en menos de tres minutos te \n realizan la transferencia a tu tarjeta, solo \n es cuestión de poco tiempo.', img: require('@/assets/images/Product02.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Ingenieros', txt2: 'Genial! Super fácil de completar los datos \n y muy óptimo, en espera del resultado y \n tengo buenas espectativas según las \n referencias que he podido leer y la cantidad \n tan alta de de buenas', img: require('@/assets/images/Product03.png') },
        { id: '4', name: 'Alhertina Rayon', txt: 'Empresarios', txt2: '“Me gusta PastaBasta porque es muy \n rápido en darme el dinero que necesito \n y si tienes alguna duda se comunican \n inmediatamente. Por supuesto, lo \n recomiendo.”', img: require('@/assets/images/Product04.png') }
      ],
      visibleItemCount: 3,
      currentIndex: 0,
      inner_4items: [
        { img: require('@/assets/images/inner_4_1.png'), title: 'Operación simple y rápida', list2: [{ txt: 'Descargue, instale la aplicación y solicite un préstamo rápido' }, { txt: 'Aprobar archivos automáticamente' }, { txt: 'El sistema aprueba automáticamente el monto del préstamo.' }] },
        { img: require('@/assets/images/inner_4_2.png'), title: 'Interés bajo', list2: [{ txt: 'El interés del préstamo es muy competitivo y \n ofrece ofertas especiales' }, { txt: 'El procedimiento es sencillo, siempre que la \n cédula de identidad.' }] },
        { img: require('@/assets/images/inner_4_3.png'), title: 'Préstamo rápido', list2: [{ txt2: 'Una vez aprobado el sistema, el dinero se transferirá \n directamente a su tarjeta bancaria.' }] }
      ],
      inner_3items: [
        { img: require('@/assets/images/list07.png'), txt: 'Puede elegir la cantidad y \n el límite de tiempo' },
        { img: require('@/assets/images/list10.png'), txt: 'Puede solicitar un préstamo \n en cualquier momento' },
        { img: require('@/assets/images/list08.png'), txt: 'Preste dinero todo el día los \n 365 días, no se preocupe por los \n fines de semana y feriados' },
        { img: require('@/assets/images/list11.png'), txt: 'Solo necesitas enviar una solicitud, \n te atenderemos durante todo el \n proceso' }
      ],
      inner6ItemLeftList: [
        { title: 'apexlendinghelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: '78 Ogunnusi Road, Ojodu Berger, Lagos State.', img: require('@/assets/images/address.png') },
        { title: '9AM-5PM', img: require('@/assets/images/time.png') }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },

    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=moneda.come.creditoapp', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top{
  min-width: 1200px;
  background: #fff;
  white-space: pre-line;
  .inner{
    width: 100%;
    padding:60px calc((100% - 1100px) / 2);
    margin: 0 auto;
  }
  .inner1{
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    padding:30px calc((100% - 1100px) / 2);
    background: url("~@/assets/images/loanImg/inner1_bg.png") #214BC0 no-repeat right;
    background-size: 45% auto;
    height: 550px;
    display: flex;
    justify-content: space-between;
    .left_item{
      box-sizing: border-box;
      border-radius: 10px;
      h1{
        font-size: 35px;
        color: #FFFFFF;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 50px;
        span{
          color: #00BB53;
        }
      }
      h4{
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1){
        margin-top: 20px;
      }
      .item_info{
        margin: 20px 0;
        display: flex;
        // height: 170px;
        .info_img1{
          width: 25px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 16px;
            color: #FFFFFF;
            align-items: center;
            margin-bottom: 10px;
            line-height: 30px;
            img{
              margin-right: 10px;
              margin-bottom: 25px;
            }
          }
        }
        .info_img2{
          width: 135px;
          height: 135px;
        }
      }
      .item_download{
        // padding-left: 22px;
        cursor: pointer;
        margin-top: 30px;
        color: white;
        border-radius: 5px;
        img{
          width: 190px;
          height: 60px;
          margin-right: 5px;
        }
      }
    }
    .right_item{
      img{
        height: 376px;
        margin-right: 30px;
      }
    }
  }
  .inner2{
    position: relative;
    padding:90px calc((100% - 1100px) / 2) 80px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    background: #F7F7F7;
    h3{
      font-size: 32px;
      text-align: center;
      margin-bottom: 60px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      background: #FFFFFF;
      border: 1px solid #E2E2E2;
      width: 19%;
      text-align: center;
      color:black;
      padding: 25px 0;
      border-radius: 5px;
      .item_info{
        margin: 30px 0px 0;
      }
      .bg{
        width:70px;
        margin: 0 auto;
        display: block;
      }
      .bg2{
        display: block;
        width: 45%;
        margin: -90px auto 0;
      }
      h1{
        font-size:14px;
        // line-height: 50px;
        margin:0 0 15px 0;
      }
      p{
        color: #666666;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        padding: 5px;
      }
    }
  }
  .inner3{
    position: relative;
    background: url("~@/assets/images/loanImg/bn.png") no-repeat center bottom;
    background-size: 100% 60%;
    padding:50px calc((100% - 1300px) / 2) 0;
    .phone{
      position: absolute;
      top: 210px;
      left: 50%;
      transform: translate(-50%);
      width: 350px;
      img{
        width: 100%;
      }
    }
    .title{
      text-align: center;
      margin-bottom: 90px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #4776FD;
        //   margin-top: 20px;
        // }
      }
    }
    .list{
      width: 1400px;
      margin: 0 auto;
      overflow: hidden;
      // padding: 50px 0px;
      li{
        width: 650px;
        float: left;
        height: 210px;
        margin-bottom: 38px;
        img{
          width: 30px;
          height: 30px;
          margin-bottom: 20px;
        }
        .text1{
          font-size: 22px;
          color: #333333;
          font-weight: 700;
          line-height: 45px;
        }
        .text2{
          font-size: 14px;
          color: #666666;
          line-height: 19px;
        }
        &.left_item{
          text-align: left;
        }
        &.right_item{
          text-align: right;
        }
        &.last{
          .text1,.text2{
            color: #ffffff;
          }
        }
      }
    }
  }
  .inner6{
    background: #F7F7F7;
    padding: 50px calc((100% - 1300px) / 2) 60px;
    h3{
      font-size: 30px;
      color: white;
      text-align: left;
      // margin-bottom: 20px;
      padding-left: 70px;
      position: relative;
      margin-bottom: 60px;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #00BB53;
      //   margin: 20px auto 0;
      // }
    }
    p{
      padding: 10px 0px 0;
      font-size: 12px;
      color: #666666;
      text-align: left;
      line-height: 18px;
    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 320px;
    }
    .carousel {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .carousel-wrapper {
      display: flex;
      width: 100%;
      justify-content:space-around;
      align-items: center;
    }
    .carousel-item {
      width: 23% !important;
      // height: 220px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      color: #fff;
      border-radius: 15px;
      font-size: 18px;
      padding: 30px 20px;
      margin: 0 20px;
      .stars{
        width: 10px;
        height: 10px;
        margin-right: 3px;
      }
      h1{
        color: black;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
      }
      h2{
        color: black;
        font-size: 12px;
        line-height: 18px;
        font-weight: normal;
        margin-bottom: 3px;
      }
      img{
        width: 70px;
        height: 70px;
        margin-right: 20px;
      }
      p{
        margin-top: 10px;
        height: 130px;
      }
      .item_download_2{
        width: 100%;
        vertical-align:middle;
        align-items: flex-end;
        p{
          margin: 0;
          padding: 0;
          color: black;
          height: auto;
          font-weight: bold;
          font-size: 15px;
          line-height: 30px;
        }
        span{
          color: #FFAC01;
          font-size: 13px;
          margin-left: 5px;
          font-weight: bold;
        }
        .downloan{
          width: 110px;
          height: 32px;
          margin: 0 0 0 25px;
        }
      }
    }
    .carousel-button {
      // position: absolute;
      // padding: 20px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      color: #555;
      outline: none;
      cursor: pointer;
      img{
        width: 70px;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s, transform 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateX(200px);
    }
  }
}
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 550px;
      position: relative;
      background-size: 100% auto;
      background: #214BC0;
      .left_item{
        // margin: 0px 10px 10px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 14px;
          line-height: 35px;
          // line-height: 50px;
          color: white !important;
          padding-left: 10px;
        }
        h4{
          color: white;
          font-size: 12px;
          margin: 0 !important;
          padding-left: 10px;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
        .item_info{
          background: #063EA4;
          padding-left: 10px;
          margin: 30px 0;
          justify-content: center;
          .info_tips{
            p{
              font-size: 12px;
            }
            img{
              width: 15px;
            }
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      h3{
        width: 100%;
        font-size: 20px;
        text-align: center;
      }
      .inner2_item{
        flex-direction: column;
        width: 100%;
        .content{
          width: 80%;
          margin: 0 auto 30px;
        }
      }
    }
    .inner3{
      padding:  50px 0 0;
      background-size: 100% auto;
      .title{
        width: 100%;
        margin-bottom: 20px;
        h3{
          text-align: center;
          margin-bottom: 30px;
        }
      }
      .text1{
        text-align: center;
        line-height: 30px !important;
        margin-bottom: 10px;
      }
      .text2{
        font-size: 12px !important;
      }
      p{
        padding: 0 20px;
        line-height: 20px;
        text-align: left !important;
        color: black !important;
      }
      .phone{
        width: 200px;
        position: relative;
        top: 0;
        margin: 30px 0;
      }
      .list{
        width: 100%;
        display: flex;
        flex-direction: column;
        li{
          width: 100%;
          height: 100px;
          margin-bottom: 15px;
        }
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner6{
      padding: 30px 20px 30px;
      position: relative;
      display: none;
      h3{
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: 320px;
      }
      .carousel-item{
        min-width: 150px !important;
        width: 45% !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        h1{
          font-size: 12px;
          padding: 0 !important;
        }
        img{
          margin-bottom: 10px;
        }
        p{
          display: none;
        }
      }
      .carousel-button{
        position: absolute;
      }
    }
  }
}
</style>
